import { setContext } from '@apollo/client/link/context';
import type { Auth0ContextInterface } from '@auth0/auth0-react';

const cookieDomain = import.meta.env.VITE_GRAPHQL_COOKIE_DOMAIN;

type GetAuth0Token = Auth0ContextInterface['getAccessTokenSilently'];

export const authLink = (getTokenSilently: GetAuth0Token) =>
  setContext(async () => {
    const { access_token, expires_in } = await getTokenSilently({
      detailedResponse: true,
    });
    // The JWT is also needed on the cookie because web sockets don't support Authorization headers
    if (!document.cookie.includes(access_token)) {
      // We might hit either api.* or go.* to reach the search endpoint, so our cookie needs to be
      // available on both subdomains (only matters when deployed, not when running locally because
      // the domain is the same locally, regardless).

      // First, though, we need to make sure we've cleared out any cookie that was defined without
      // a domain, because that would take precedence over a cookie with a domain:
      document.cookie = `JWT=; path=/; max-age=0`;

      // Then we can set one with the right domain:
      document.cookie = `JWT=${access_token}; domain=${cookieDomain}; path=/; max-age=${expires_in}`;
    }
    return {
      headers: {
        Authorization: `Bearer ${access_token}`,
      },
    };
  });
